import { Filters, SortingRule } from 'react-table';
import { IFilter, ITableParameters } from '@interfaces';
import { getDefaultTableParameters } from '@services';

const getFiltersData = <T extends {}>(filters: Filters<T>): Record<string, string> => filters
  .reduce(
    (result: Record<string, any>, filter: IFilter) => {
      if (filter.value !== null) {
        const filterValue = typeof filter.value === 'string'
          ? filter.value.trim()
          : typeof filter.value === 'undefined'
            ? ''
            : filter.value;
        result[filter.id] = filterValue;
      }
      return result;
    },
    {}
  );

const getSortData = <T extends object>(sortBy: Array<SortingRule<T>>): Record<string, string> => typeof sortBy[0] !== 'undefined'
  ? {
    sortField: sortBy[0].id,
    sortDirection: sortBy[0].desc ? 'desc' : 'asc'
  }
  : {};

export function getRequestParametersFromTableParameters<T extends {}>(
  options: Partial<ITableParameters<T>> = {},
  requestParameters: Record<string, string> = {}
): Record<string, string | number> {
  const defaultParameters: ITableParameters<T> = getDefaultTableParameters<T>();
  const parameters: ITableParameters<T> = { ...defaultParameters, ...options };

  const filterData: Record<string, string> = getFiltersData<T>(parameters.filters);
  const paginationData = {
    page: parameters.page!,
    pageSize: parameters.pageSize!
  };
  const sortData = getSortData(parameters.sortBy);

  return {
    ...filterData,
    ...paginationData,
    ...sortData,
    ...requestParameters
  };
}
