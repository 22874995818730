import React, { ReactElement } from 'react';
import { classNameArrayToString, useDataAttributes } from '@proliance-ai/design-system';
import { TabProperties } from './Tabs.typings';

const Tab = ({
  label,
  active,
  disabled,
  clickHandler,
  className,
  ...props
}: TabProperties): ReactElement => {
  const key = props['data-key'];
  const isActive = key === active;
  const { dataAttributes } = useDataAttributes({
    test: {
      tab: key.toLowerCase(),
      'tab-active': isActive
    },
    guide: {
      tab: key.toLowerCase(),
      'tab-active': isActive
    }
  });

  const classNameString = classNameArrayToString([
    'tabItem',
    className,
    isActive && 'active',
    disabled && 'disabled'
  ]);

  const onClick = () => {
    if (!disabled) {
      clickHandler(key);
    }
  };

  return (
    <li
      className={ classNameString }
      onClick={ onClick }
      { ...dataAttributes }
    >
      { label }
    </li>
  );
};

export default Tab;
