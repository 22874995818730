import i18n from 'i18next';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { mergeMap, share, take, tap } from 'rxjs/operators';
import { sidebarService } from '@proliance-ai/react-ui';
import {
  Asset,
  AssetFormKey,
  AssetManagementFilterData,
  AssetTypeKey,
  Collection,
  Locale,
  SidebarKnowledgeBaseData,
  SortItem,
  ThirdParty,
  TranslationInfo,
  User
} from '@proliance-ai/typings';
import { cleanEmptyValues, setObjectValue } from '@proliance-ai/utilities';
import { IDictionaryResponse } from '@proliance-ai/design-system';
import { replaceHistoryState } from '@router';
import { dictionaryService } from '@services';
import { assetManagementApiService, commentApiService } from '@services/api';
import {
  AssetManagementService,
  AssetManagementSidebarData,
  GetCountryTitleOptions,
  KnowledgeBaseDataCache
} from './assetManagement.typings';
import { assetFormKeyToRouteTabMapping, assetManagementTabsKeyToRouteTabMapping } from './data';
import { thirdPartyCollectionMapper } from './mappers';
import { getIcon, getNextStep, getPriority, getPriorityPreset } from './utilities';

export const assetManagementInitialState: SortItem<Asset>[] = [ {
  sortField: 'name',
  sortDirection: 'asc'
} ];

const sidebar = new BehaviorSubject<undefined | AssetManagementSidebarData<Asset>>(undefined);
const sidebar$ = sidebar.asObservable();

const showNext = new BehaviorSubject<undefined | boolean>(undefined);

const routeHandler = (assetFormKey: AssetTypeKey, id: string, sidebar?: AssetFormKey): void => {
  const isDefaultTab = assetFormKey === 'Software';
  const routeName = !!sidebar
    ? isDefaultTab
      ? 'assetManagementTabAsset'
      : 'assetManagementTabAssetSidebar'
    : isDefaultTab
      ? 'assetManagementTab'
      : 'assetManagementTabAsset';
  const routeParameters = {
    routeTab: isDefaultTab
      ? id
      : assetFormKeyToRouteTabMapping[assetFormKey],
    id: isDefaultTab
      ? sidebar
      : id,
    sidebar: isDefaultTab
      ? undefined : sidebar
  };
  replaceHistoryState(routeName, routeParameters);
};

const openSidebar = <T extends Asset>(data: AssetManagementSidebarData<T>): void => {
  const { name, asset: { id: assetId } } = data;
  if (sidebar.value?.name === name && sidebar.value?.asset.id === data.asset.id) {
    return;
  }
  showNext.next(undefined);
  sidebar.next(data);
  const { id } = sidebarService.sidebarOptions.value;
  routeHandler(id as AssetTypeKey, assetId, name);
  updateSidebar(id, data); // TODO fix documents data handling
};

const updateSidebar = <T extends Asset>(id: string, data: AssetManagementSidebarData<T>): void => {
  if (typeof sidebar.value !== 'undefined') {
    sidebarService.updateContentProperties(id, { ...sidebar.value, ...data });
  }
};

const filterRouteHandler = (routeTab: string): void => {
  const isDefaultTab = !routeTab;
  const routeName = isDefaultTab
    ? 'assetManagement'
    : 'assetManagementTab';
  const routeParameters = {
    routeTab: isDefaultTab
      ? undefined
      : routeTab
  };
  replaceHistoryState(routeName, routeParameters);
};
const closeSidebar = (handleRoute: boolean = true): void => {
  if (typeof sidebar.value !== 'undefined') {
    if (handleRoute) {
      const { id } = sidebarService.sidebarOptions.value;
      const { asset: { id: assetId } } = sidebar.value;
      routeHandler(id as AssetTypeKey, assetId);
    }
    sidebar.next(undefined);
  } else {
    const sidebarOptions = sidebarService.sidebarOptions.value;
    if (sidebarOptions.id === 'assetManagementFilter') {
      if (handleRoute) {
        const tab = sidebarOptions?.content?.contentElement?.props?.tab;
        const routeTab: string = assetManagementTabsKeyToRouteTabMapping[tab];
        filterRouteHandler(routeTab);
      }
      sidebarService.hide();
    }
  }
};

sidebarService.isOpen$
  .subscribe((isOpen: boolean): void => {
    if (!isOpen && !!sidebar.value) {
      closeSidebar();
    }
  });

let dictionaryCache: Array<IDictionaryResponse<TranslationInfo>>;
const getCountryTitleFromDictionaryData = ({
  country,
  locale,
  dictionaryData
}: GetCountryTitleOptions): string => dictionaryData
  .find((item: IDictionaryResponse<TranslationInfo>): boolean => item.id === country)?.title[locale] || '';

const getCountryTitle = (country: string): Promise<string> => new Promise((resolve) => {
  if (!dictionaryCache) {
    dictionaryService
      .getData('country')
      .pipe(take(1))
      .subscribe((dictionaryData: Array<IDictionaryResponse<TranslationInfo>>) => {
        dictionaryCache = dictionaryData;
        resolve(getCountryTitleFromDictionaryData({ country, locale: i18n.language as Locale, dictionaryData }));
      });
  } else {
    resolve(getCountryTitleFromDictionaryData({
      country,
      locale: i18n.language as Locale,
      dictionaryData: dictionaryCache
    }));
  }
});

const getThirdPartyCollection = (parameters?: Record<string, any>): Observable<Collection<ThirdParty>> => assetManagementApiService
  .getThirdPartyCollection(parameters)
  .pipe(mergeMap(thirdPartyCollectionMapper));

const dataSidebarKnowledgeBaseDataCache: KnowledgeBaseDataCache<SidebarKnowledgeBaseData> = {};
const observableSidebarKnowledgeBaseDataCache: KnowledgeBaseDataCache<Observable<SidebarKnowledgeBaseData>> = {};

const getSidebarKnowledgeBaseData = (
  assetType: AssetTypeKey,
  sidebarName: AssetFormKey
): Observable<SidebarKnowledgeBaseData> => {
  const observableCache = observableSidebarKnowledgeBaseDataCache[assetType]?.[sidebarName];
  if (observableCache) {
    return observableCache;
  }
  const dataCache = dataSidebarKnowledgeBaseDataCache[assetType]?.[sidebarName];
  if (dataCache) {
    return of(dataCache);
  }
  const request = assetManagementApiService
    .getSidebarKnowledgeBaseData(assetType, sidebarName)
    .pipe(
      tap((data: SidebarKnowledgeBaseData) => {
        delete observableSidebarKnowledgeBaseDataCache[assetType]?.[sidebarName];
        setObjectValue(dataSidebarKnowledgeBaseDataCache, [ assetType, sidebarName ], data);
      }),
      share()
    );
  setObjectValue(observableSidebarKnowledgeBaseDataCache, [ assetType, sidebarName ], request);

  return request;
};

const filterAssignee$ = new BehaviorSubject<null | User>(null);
const filter$ = new BehaviorSubject<AssetManagementFilterData>({});
const getFilterCount = (): number => Object.keys(cleanEmptyValues(filter$.value)).length;

export const assetManagementService: AssetManagementService = {
  sidebar,
  sidebar$,
  openSidebar,
  updateSidebar,
  closeSidebar,

  showNext,

  filterAssignee$,
  filter$,
  getFilterCount,

  getCountryTitle,

  getIcon,
  getNextStep,

  getPriority,
  getPriorityPreset,

  deleteFile: assetManagementApiService.deleteFile.bind(assetManagementApiService),

  getThirdPartyCollection,
  getThirdParty: assetManagementApiService.getThirdParty.bind(assetManagementApiService),
  getThirdPartyTemplateCollection: assetManagementApiService.getThirdPartyTemplateCollection.bind(assetManagementApiService),
  getThirdPartySuggestedSoftwareTemplateCollection: assetManagementApiService.getThirdPartySuggestedSoftwareTemplateCollection.bind(assetManagementApiService),
  getThirdPartySuggestedDataStorageTemplateCollection: assetManagementApiService.getThirdPartySuggestedDataStorageTemplateCollection.bind(assetManagementApiService),
  createThirdParty: assetManagementApiService.createThirdParty.bind(assetManagementApiService),
  updateThirdParty: assetManagementApiService.updateThirdParty.bind(assetManagementApiService),
  deleteThirdParty: assetManagementApiService.deleteThirdParty.bind(assetManagementApiService),
  getInstrumentDataList: assetManagementApiService.getInstrumentDataList.bind(assetManagementApiService),

  getSoftwareCollection: assetManagementApiService.getSoftwareCollection.bind(assetManagementApiService),
  getSoftware: assetManagementApiService.getSoftware.bind(assetManagementApiService),
  getSoftwareTemplateCollection: assetManagementApiService.getSoftwareTemplateCollection.bind(assetManagementApiService),
  getSoftwareSuggestedThirdPartyTemplateCollection: assetManagementApiService.getSoftwareSuggestedThirdPartyTemplateCollection.bind(assetManagementApiService),
  createSoftware: assetManagementApiService.createSoftware.bind(assetManagementApiService),
  updateSoftware: assetManagementApiService.updateSoftware.bind(assetManagementApiService),
  deleteSoftware: assetManagementApiService.deleteSoftware.bind(assetManagementApiService),
  getSoftwareCategories: assetManagementApiService.getSoftwareCategories.bind(assetManagementApiService),

  getDataStorageCollection: assetManagementApiService.getDataStorageCollection.bind(assetManagementApiService),
  getDataStorage: assetManagementApiService.getDataStorage.bind(assetManagementApiService),
  getDataStorageTemplateCollection: assetManagementApiService.getDataStorageTemplateCollection.bind(assetManagementApiService),
  getDataStorageSuggestedThirdPartyTemplateCollection: assetManagementApiService.getDataStorageSuggestedThirdPartyTemplateCollection.bind(assetManagementApiService),
  createDataStorage: assetManagementApiService.createDataStorage.bind(assetManagementApiService),
  updateDataStorage: assetManagementApiService.updateDataStorage.bind(assetManagementApiService),
  deleteDataStorage: assetManagementApiService.deleteDataStorage.bind(assetManagementApiService),

  getCommentCollection: commentApiService.getCommentCollection.bind(commentApiService),
  createComment: commentApiService.createComment.bind(commentApiService),
  updateComment: commentApiService.updateComment.bind(commentApiService),
  deleteComment: commentApiService.deleteComment.bind(commentApiService),

  getSoftwareTemplate: assetManagementApiService.getSoftwareTemplate.bind(assetManagementApiService),
  getThirdPartyTemplate: assetManagementApiService.getThirdPartyTemplate.bind(assetManagementApiService),
  getDataStorageTemplate: assetManagementApiService.getDataStorageTemplate.bind(assetManagementApiService),

  getTransitionById: assetManagementApiService.getTransitionById.bind(assetManagementApiService),
  updateTransition: assetManagementApiService.updateTransition.bind(assetManagementApiService),
  getSoftwareVendorContact: assetManagementApiService.getSoftwareVendorContact.bind(assetManagementApiService),
  getSidebarKnowledgeBaseData
};
