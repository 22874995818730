import React, { ReactElement } from 'react';
import { classNameArrayToString } from '@proliance-ai/design-system';
import { TabContentProperties } from '@shared/ui';

const TabContent = ({
  hidden,
  children
}: TabContentProperties): ReactElement => {
  const classNameString = classNameArrayToString([
    'tabContent',
    hidden && 'hidden'
  ]);
  return <div className={ classNameString }>{ children }</div>;
};

export default TabContent;
