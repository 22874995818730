import { Observable } from 'rxjs';
import {
  AssetFormKey,
  AssetTemplateDataStorageData,
  AssetTemplateSoftwareData,
  AssetTemplateThirdPartyData,
  AssetTypeKey,
  Collection,
  CustomCategory,
  DataStorage,
  DataStorageCreationData,
  DataStorageTemplate,
  InstrumentData,
  SidebarKnowledgeBaseData,
  Software,
  SoftwareCreationData,
  SoftwareTemplate,
  ThirdParty,
  ThirdPartyCreationData,
  ThirdPartyTemplate,
  WorkFlowData,
  UpdateTransition,
  VendorContact
} from '@proliance-ai/typings';
import { Api } from '@services/api';
import { camelCaseToSnakeCase, Case } from '@proliance-ai/utilities';

const apiUrl = '/api/survey/asset';

class AssetManagementApiService extends Api {
  public deleteFile(id: string, documentId: string): Observable<null> {
    return this.delete({
      url: `third-party/${ id }/document/${ documentId }`
    });
  }
  public getThirdPartyCollection(parameters?: Record<string, string | boolean>): Observable<Collection<ThirdParty>> {
    return this.get<Collection<ThirdParty>>({
      url: 'third-party',
      parameters: { pageSize: 1000, ...parameters }
    });
  }
  public getThirdParty(id: string): Observable<ThirdParty> {
    return this.get<ThirdParty>({
      url: `third-party/${ id }`
    });
  }
  public getThirdPartyTemplateCollection(parameters?: Record<string, string | boolean>): Observable<Collection<ThirdPartyTemplate>> {
    return this.get<Collection<ThirdPartyTemplate>>({
      url: 'template/third-party',
      parameters
    });
  }
  public getThirdPartySuggestedSoftwareTemplateCollection(parameters?: Record<string, string | boolean>): Observable<Collection<SoftwareTemplate>> {
    return this.get<Collection<SoftwareTemplate>>({
      url: 'template/third-party/suggested-software',
      parameters
    });
  }
  public getThirdPartySuggestedDataStorageTemplateCollection(parameters?: Record<string, string | boolean>): Observable<Collection<DataStorageTemplate>> {
    return this.get<Collection<DataStorageTemplate>>({
      url: 'template/third-party/suggested-data-storage',
      parameters
    });
  }
  public createThirdParty(body: ThirdPartyCreationData): Observable<ThirdParty> {
    return this.post<ThirdParty>({
      url: 'third-party',
      body
    });
  }
  public updateThirdParty(id: string, body: Partial<ThirdParty>): Observable<ThirdParty> {
    return this.patch<ThirdParty>({
      url: `third-party/${ id }`,
      body
    });
  }
  public deleteThirdParty(id: string): Observable<void> {
    return this.delete({
      url: `third-party/${ id }`
    });
  }
  public getInstrumentDataList(country: string): Observable<InstrumentData[]> {
    return this.get({
      url: `third-party/instruments?country=${ country }`
    });
  }

  public getSoftwareCollection(parameters?: Record<string, string | boolean>): Observable<Collection<Software>> {
    return this.get<Collection<Software>>({
      url: 'software',
      parameters: { pageSize: 1000, ...parameters }
    });
  }
  public getSoftware(id: string): Observable<Software> {
    return this.get<Software>({
      url: `software/${ id }`
    });
  }
  public getSoftwareTemplateCollection(parameters?: Record<string, string | boolean>): Observable<Collection<SoftwareTemplate>> {
    return this.get<Collection<SoftwareTemplate>>({
      url: 'template/software',
      parameters
    });
  }
  public getSoftwareSuggestedThirdPartyTemplateCollection(parameters?: Record<string, string | boolean>): Observable<Collection<ThirdPartyTemplate>> {
    return this.get<Collection<ThirdPartyTemplate>>({
      url: 'template/software/suggested-third-party',
      parameters
    });
  }
  public createSoftware(body: SoftwareCreationData): Observable<Software> {
    return this.post<Software>({
      url: 'software',
      body
    });
  }
  public updateSoftware(id: string, body: Partial<Software>): Observable<Software> {
    return this.patch<Software>({
      url: `software/${ id }`,
      body
    });
  }
  public deleteSoftware(id: string): Observable<void> {
    return this.delete({
      url: `software/${ id }`
    });
  }
  public getSoftwareCategories(): Observable<CustomCategory[]> {
    return this.get({
      url: `custom/software-category`
    });
  }
  public getDataStorageCollection(parameters?: Record<string, string | boolean>): Observable<Collection<DataStorage>> {
    return this.get<Collection<DataStorage>>({
      url: 'data-storage',
      parameters: { pageSize: 1000, ...parameters }
    });
  }
  public getDataStorage(id: string): Observable<DataStorage> {
    return this.get<DataStorage>({
      url: `data-storage/${ id }`
    });
  }
  public getDataStorageTemplateCollection(parameters?: Record<string, string | boolean>): Observable<Collection<DataStorageTemplate>> {
    return this.get<Collection<DataStorageTemplate>>({
      url: 'template/data-storage',
      parameters
    });
  }
  public getDataStorageSuggestedThirdPartyTemplateCollection(parameters?: Record<string, string | boolean>): Observable<Collection<ThirdPartyTemplate>> {
    return this.get<Collection<ThirdPartyTemplate>>({
      url: 'template/data-storage/suggested-third-party',
      parameters
    });
  }
  public createDataStorage(body: DataStorageCreationData): Observable<DataStorage> {
    return this.post<DataStorage>({
      url: 'data-storage',
      body
    });
  }
  public updateDataStorage(id: string, body: Partial<DataStorage>): Observable<DataStorage> {
    return this.patch<DataStorage>({
      url: `data-storage/${ id }`,
      body
    });
  }
  public deleteDataStorage(id: string): Observable<void> {
    return this.delete({
      url: `data-storage/${ id }`
    });
  }
  public getSoftwareTemplate(id: string): Observable<AssetTemplateSoftwareData> {
    return this.get<AssetTemplateSoftwareData>({
      url: `template/software/${ id }`
    });
  }
  public getThirdPartyTemplate(id: string): Observable<AssetTemplateThirdPartyData> {
    return this.get<AssetTemplateThirdPartyData>({
      url: `template/third-party/${ id }`
    });
  }
  public getDataStorageTemplate(id: string): Observable<AssetTemplateDataStorageData> {
    return this.get<AssetTemplateDataStorageData>({
      url: `template/data-storage/${ id }`
    });
  }

  public getSidebarKnowledgeBaseData(assetType: AssetTypeKey, sidebarName: AssetFormKey): Observable<SidebarKnowledgeBaseData> {
    return this.get<SidebarKnowledgeBaseData>({
      url: `knowledge-base/sidebar`,
      parameters: {
        assetType: camelCaseToSnakeCase(assetType, Case.upper),
        sidebarName
      }
    });
  }
  public getTransitionById(id: string): Observable<WorkFlowData> {
    return this.get<WorkFlowData>({
      url: `workflow?assetId=${ id }`
    });
  }
  public updateTransition(data: UpdateTransition): Observable<WorkFlowData> {
    const { transitionType, assetId, ...body } = data;
    const parameters = { transitionType, assetId  };
    return this.post<WorkFlowData>({
      url: 'workflow',
      parameters,
      body
    });
  }
  public getSoftwareVendorContact(id: string): Observable<VendorContact> {
    return this.get<VendorContact>({
      url: `template/software/${ id }/vendor-contact`
    });
  }
}

export const assetManagementApiService = new AssetManagementApiService(apiUrl);
