import React, {
  Children,
  cloneElement,
  ReactElement,
  useEffect,
  useState
} from 'react';
import { useDataAttributes } from '@proliance-ai/design-system';
import { classNameArrayToString } from '@proliance-ai/utilities';
import { TabsPreset, TabsProperties, Tab, TabContent } from './index';

import './Tabs.styl';

const Tabs = ({
  children,
  preset = TabsPreset.material,
  title,
  activeTab,
  disabledTabList = [],
  actions,
  onChange,
  tabFormatter,
  dataAttributesDictionary = {},
  ...props
}: TabsProperties): ReactElement => {
  const {
    test = { tabs: '' },
    guide = { tabs: '' }
  } = dataAttributesDictionary;
  const { dataAttributes } = useDataAttributes({ test, guide });
  const tabListDataAttributesValue = { tabList: '' };
  const { dataAttributes: tabListDataAttributes } = useDataAttributes({
    test: tabListDataAttributesValue,
    guide: tabListDataAttributesValue
  });

  const getEnabledTab: string = children
    .reduce(
      (result: string, child: ReactElement): string => !result && !child.props.disabled
        ? child.props['data-key']
        : result,
      ''
    );

  const getTabValue = (active?: string): string =>
    typeof active !== 'undefined'
      ? disabledTabList.includes(active)
        ? getEnabledTab
        : active
      : getEnabledTab;

  const [ tab, setTab ] = useState<string>(getTabValue(activeTab));

  useEffect(() => {
    setTab(getTabValue(activeTab));
  }, [ activeTab ]);

  const clickHandler = (key: string): void => {
    if (key === tab) {
      return;
    }
    setTab(key);
    if (typeof onChange !== 'undefined') {
      onChange(key);
    }
  };

  const titleElement = title
    ? (
      <li key="title" className="tabsTitle">
        { title }
      </li>
    )
    : null;

  const actionsElement = actions
    ? (
      <li key="title" className="tabsActions">
        { actions }
      </li>
    )
    : null;

  const labelElementList = children
    .map(
      (child: ReactElement): ReactElement => {
        const key = typeof tabFormatter === 'undefined'
          ? child.props['data-key']
          : tabFormatter(child.props['data-key']);
        const label = child.props['data-label'];
        const classNameValue = child.props['data-className'];
        const disabled = disabledTabList.includes(key);
        return (
          <Tab
            key={ key }
            data-key={ key }
            label={ label }
            active={ tab }
            disabled={ disabled }
            className={ classNameValue }
            clickHandler={ clickHandler }
          />
        );
      }
    );

  const childrenAddPermissionProperties = (
    children: ReactElement[],
    demo: boolean = false,
    permissionClassName?: string,
    props?: Record<string, string>
  ): ReactElement[] =>
    Children.map(children, (item: ReactElement) => {
      const className = classNameArrayToString([ item.props?.className, permissionClassName ]);
      return cloneElement(
        item, {
          ...item.props,
          className,
          demo: `${ demo }`,
          ...props
        });
    });

  const items = children
    .map((child: ReactElement): ReactElement | null => {
      const {
        'data-key': key,
        'data-reload': reload,
        demo,
        className: permissionClassName
      } = child.props;
      const tabKey = typeof tabFormatter === 'undefined'
        ? key
        : tabFormatter(key);
      const childrenElements: ReactElement[] = child.props.children;
      if (tabKey === tab) {
        return (
          <TabContent hidden={ false } key={ tabKey }>
            { childrenAddPermissionProperties(childrenElements, demo, permissionClassName, { tab }) }
          </TabContent>
        );
      }
      if (reload) {
        return null;
      }
      return (
        <TabContent hidden={ true } key={ tabKey }>
          { childrenAddPermissionProperties(childrenElements, demo, permissionClassName) }
        </TabContent>
      );
    });

  const className = classNameArrayToString([
    props.className,
    preset,
    'tabContainer'
  ]);

  return (
    <div
      { ...props }
      { ...dataAttributes }
      className={ className }
    >
      <ul
        className="tabList"
        { ...tabListDataAttributes }
      >
        { [ titleElement, ...labelElementList, actionsElement ] }
      </ul>
      { items }
    </div>
  );
};

export default Tabs;
