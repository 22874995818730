import React, { ReactNode } from 'react';
import { TFunction } from 'i18next';
import { i18n } from '@services';
import { MultipleFieldErrors } from 'react-hook-form';

import styles from './ErrorFactory.styl';

export type MessageObject = {
  key: string;
  values: string[];
  [key: string]: any;
};
export type Message = string | MessageObject;
type ErrorRenderer = (data: { message: Message; messages?: MultipleFieldErrors }) => ReactNode;
type ErrorRendererFactory = (t: TFunction, fieldName: string, params?: object, valuesTranslationKey?: ((valueKey: string) => string) | string) => ErrorRenderer;

interface HandleBaseOptions {
  t: TFunction;
  fieldName: string;
  params: object;
}

interface HandleMessageOptions extends HandleBaseOptions {
  message: string;
}

interface HandleObjectMessageOptions extends HandleBaseOptions {
  message: MessageObject;
  valuesTranslationKey?: ((valueKey: string) => string) | string;
}

const handleMessage = (options: HandleMessageOptions): string => {
  const { t, message, fieldName, params } = options;
  return i18n.exists(`form:errors.${ message }`)
    ? t(`form:errors.${ message }`, { fieldName, ...params })
    : message;
};
const handleObjectMessage = (options: HandleObjectMessageOptions): string => {
  const { t, message, fieldName, params, valuesTranslationKey } = options;
  const { key, values: valuesData, ...data } = message;
  const values = typeof valuesData !== 'undefined'
    ? valuesData
      .map((valueKey: string) => typeof valuesTranslationKey === 'function'
        ? t(valuesTranslationKey(valueKey))
        : t(`${ valuesTranslationKey }.${ valueKey }`))
      .join(', ')
    : undefined;
  return i18n.exists(`form:errors.${ key }`)
    ? t(`form:errors.${ key }`, { fieldName, values, ...params, ...data })
    : key;
};

const ErrorFactory: ErrorRendererFactory = (
  t,
  fieldName,
  params = {},
  valuesTranslationKey?: ((valueKey: string) => string) | string
) => ({ message }) => {
  const text = typeof message === 'object'
    ? handleObjectMessage({ t, message, fieldName, params, valuesTranslationKey })
    : handleMessage({ t, message, fieldName, params });
  return (
    <span className={ styles.error }>{ text }</span>
  );
};

export default ErrorFactory;
