import { ComponentProps, MutableRefObject, ReactElement } from 'react';
import { DataAttributesType } from '@proliance-ai/design-system';

export enum TabsPreset {
  default = 'default',
  material = 'material',
}

interface TabsProps {
  children: ReactElement[];
  preset?: TabsPreset;
  title?: string | ReactElement;
  activeTab?: MutableRefObject<string>;
  disabledTabList?: string[];
  actions?: ReactElement | ReactElement[];
  onChange?: (tab: string) => void;
  tabFormatter?: (key: string) => string;
  dataAttributesDictionary?: DataAttributesType;
}

export type TabsProperties = TabsProps & ComponentProps<any>;

export interface TabProperties {
  className?: string;
  label: string;
  'data-key': string;
  active: string;
  disabled: boolean;
  clickHandler: (label: string) => void;
}

export interface TabContentProperties {
  hidden: boolean;
  children: ReactElement[];
}

export type BooleanString = 'true' | 'false';
export interface TabComponentProperties<T extends string> {
  tab: T;
  className?: string;
  demo: BooleanString;
}
