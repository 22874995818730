import { Observable } from 'rxjs';
import { BusinessPremise, BusinessPremiseData, Collection, CountryAddress, Phone } from '@proliance-ai/typings';
import { ITableState, RequestOptions } from '@proliance-ai/design-system';

export enum Systems {
  ASSOCIATED_COMPANY = 'ASSOCIATED_COMPANY',
  WEBSITE = 'WEBSITE',
  FREELANCER = 'FREELANCER',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  PRINCIPAL = 'PRINCIPAL',
  DATA_STORAGE_SELF_HOSTED = 'DATA_STORAGE_SELF_HOSTED',
  DATA_STORAGE_THIRD_PARTY_HOSTED = 'DATA_STORAGE_THIRD_PARTY_HOSTED',
  DATA_STORAGE_CLOUD_HOSTED = 'DATA_STORAGE_CLOUD_HOSTED',
  SOFTWARE = 'SOFTWARE',
  BUSINESS_PREMISE = 'BUSINESS_PREMISE'
}

export type SystemsType = keyof typeof Systems;

export type SystemsStatistics = {
  [key in SystemsType]: number;
};

export interface AssociatedCompany {
  id: string;
  title: null | string;
  name: null | string;
  legalForm: null | string;
  street: null | string;
  houseNumber: null | string;
  zip: null | number;
  city: null | string;
  country: null | string;
}
export interface Software {
  id: string;
  title: null | string;
  name: null | string;
  manufacturer: null | string;
  updatedAt: string;
}
export interface ServiceProvider {
  id: string;
  title: null | string;
  name: null | string;
  legalForm: null | string;
  street: null | string;
  houseNumber: null | string;
  zip: null | number;
  city: null | string;
  country: null | string;
  company: number;
  updatedAt: string;
}

export interface Freelancer {
  id: string;
  title: null | string;
  firstName: null | string;
  lastName: null | string;
  street: null | string;
  houseNumber: null | string;
  zip: null | number;
  city: null | string;
  country: null | string;
}
export interface DataStorageSelfHosted {
  id: string;
  title: null | string;
  name: null | string;
  businessPremiseId: null | string;
  businessPremise: BusinessPremise;
  updatedAt: string;
}
export interface DataStorageThirdPartyHosted {
  id: string;
  title: null | string;
  name: null | string;
  serviceProviderId: null | string;
  serviceProvider: ServiceProvider;
  updatedAt: string;
}
export interface DataStorageCloudHosted {
  id: string;
  title: null | string;
  name: null | string;
  serviceProviderId: null | string;
  serviceProvider: ServiceProvider;
  updatedAt: string;
}
export type SystemsEntity =
  | AssociatedCompany
  | Website
  | Software
  | ServiceProvider
  | Freelancer
  | DataStorageSelfHosted
  | DataStorageThirdPartyHosted
  | DataStorageCloudHosted;

export type SystemsUrl =
  | 'business_premise'
  | 'associated-company'
  | 'website'
  | 'software'
  | 'service-provider'
  | 'principal'
  | 'freelancer'
  | 'data-storage-self-hosted'
  | 'data-storage-third-party-hosted'
  | 'data-storage-cloud-hosted';

export type SystemsApiUrl =
  | 'business_premise'
  | 'associated_company'
  | 'website'
  | 'software'
  | 'service_provider'
  | 'principal'
  | 'freelancer'
  | 'data_storage_self_hosted'
  | 'data_storage_third_party_hosted'
  | 'data_storage_cloud_hosted';

export type SystemType = 'principal' | 'website';

export interface SystemEntityData {
  id: string;
  title: string;
}
export interface WebsiteData {
  url: string;
}
export interface Website extends SystemEntityData, WebsiteData {}

export interface PrincipalDataDpo {
  name: string;
  email: string;
  phoneNumber: Phone;
  identicalAddress: boolean;
  address?: CountryAddress;
}
export interface PrincipalData {
  name: string;
  legalForm: string;
  address: CountryAddress;
  dpoPresent: boolean;
  dpo?: PrincipalDataDpo;
}
export interface Principal extends PrincipalData, SystemEntityData {}


export interface SystemsService {
  getSystemsStatistics: () => Observable<SystemsStatistics>;
  getCurrentTime: () => Observable<string>;
  getSystemsType: (value?: string) => null | Systems;
  getSystemsByType: (value: string, tableState?: Partial<ITableState<SystemsEntity>>) => Observable<null | Collection<SystemsEntity>>;
  getSystemsApiUrl: (value: string) => null | SystemsApiUrl;
  getSystemsUrl: (value: string) => null | SystemsUrl;
  createBusinessPremise: (body: BusinessPremiseData) => Observable<BusinessPremise>;
  updateBusinessPremise: (id: string, body: BusinessPremise) => Observable<BusinessPremise>;
  getSystems: <S>(systemType: SystemType, id: string) => Observable<S>;
  createSystem: <D, S>(systemType: SystemType, body: D, options?: RequestOptions) => Observable<S>;
  updateSystem: <S>(systemType: SystemType, id: string, body: S) => Observable<S>;
  deleteSystem: (systemType: SystemType, id: string) => Observable<void>;
}
